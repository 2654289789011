"use client";

import type { SupportedLanguage } from "@/utils/i18n";
import type { ReactNode } from "react";
import { useCallback, useEffect } from "react";
import { usePathname, useRouter } from "next/navigation";
import { createGenericContext } from "@/utils/createGenericContext";
import { dayjs } from "@/utils/dayjsUtils";
import { getCountryFromTimezone } from "@/utils/getCountryFromTimezone";
import { countryCookieId, languageCookieId, languages, timezoneCookieId } from "@/utils/i18n";
import useSessionStorage from "@/utils/useSessionStorage";
import { type TCountryCode } from "countries-list";
import i18next from "i18next";
import { noop } from "lodash-es";
import Cookies from "universal-cookie";
import z from "zod";
import { makeZodI18nMap } from "zod-i18n-map";
import en from "zod-i18n-map/locales/en/zod.json";
import no from "zod-i18n-map/locales/nb/zod.json";
i18next.init({
  lng: "en",
  resources: {
    no: {
      custom: {
        invalid_number: "Ikke gyldig telefonnummer",
        invalid_bounty: "Belønning må være et tall",
        invalid_linkedin_or_resume: "Enten LinkedIn eller CV må fylles ut riktig",
        invalid_vouch: "Enten e-post eller telefonnummer må fylles ut riktig",
        invalid_url: "Ikke en gyldig URL"
      },
      zod: {
        errors: {
          ...no.errors,
          too_small: {
            ...no.errors.too_small,
            string: {
              exact: "Må inneholde nøyaktig {{minimum}} bokstav(er)",
              inclusive: "Må inneholde minst {{minimum}} bokstav(er)",
              not_inclusive: "Må inneholde over {{minimum}} bokstav(er)"
            }
          },
          too_big: {
            ...no.errors.too_big,
            string: {
              exact: "Må inneholde nøyaktig {{maximum}} tegn",
              inclusive: "Må være mindre eller lik {{maximum}} tegn",
              not_inclusive: "Må inneholde under {{maximum}} tegn"
            }
          }
        }
      }
    },
    en: {
      custom: {
        invalid_number: "Not a valid phone number",
        invalid_bounty: "Bounty must be a number",
        invalid_linkedin_or_resume: "Either LinkedIn or Resume must be filled in correctly",
        invalid_vouch: "Either email or phone number must be filled in correctly",
        invalid_url: "Not a valid URL"
      },
      zod: {
        errors: {
          ...en.errors,
          too_small: {
            ...en.errors.too_small,
            string: {
              exact: "Must contain exactly {{minimum}} character(s)",
              inclusive: "Must contain at least {{minimum}} character(s)",
              not_inclusive: "Must contain over {{minimum}} character(s)"
            }
          },
          too_big: {
            ...en.errors.too_big,
            string: {
              exact: "Must contain exactly {{maximum}} character(s)",
              inclusive: "Must contain at most {{maximum}} character(s)",
              not_inclusive: "Must contain under {{maximum}} character(s)"
            }
          }
        }
      }
    },
    dk: {
      custom: {
        invalid_number: "Ikke en gyldig telefonnummer",
        invalid_bounty: "Belønning skal være et tal",
        invalid_linkedin_or_resume: "Enten LinkedIn eller CV skal udfyldes korrekt",
        invalid_vouch: "Enten e-mail eller telefonnummer skal udfyldes korrekt",
        invalid_url: "Ikke en gyldig URL"
      },
      zod: {
        errors: {
          ...no.errors,
          too_small: {
            ...no.errors.too_small,
            string: {
              exact: "Skal indeholde præcis {{minimum}} bogstav(er)",
              inclusive: "Skal indeholde mindst {{minimum}} bogstav(er)",
              not_inclusive: "Skal indeholde over {{minimum}} bogstav(er)"
            }
          },
          too_big: {
            ...no.errors.too_big,
            string: {
              exact: "Skal indeholde præcis {{maximum}} tegn",
              inclusive: "Skal være mindre eller lig med {{maximum}} tegn",
              not_inclusive: "Skal indeholde under {{maximum}} tegn"
            }
          }
        }
      }
    }
  }
}).catch(console.error);

// use default namespace
z.setErrorMap(makeZodI18nMap({
  ns: ["zod", "custom"]
}));
type SourceContext = {
  url: string;
  type: "listing" | "company" | "vouch";
  date: Date;
};
const [useLocaleProvider, LocaleContextProvider] = createGenericContext<{
  lang: SupportedLanguage;
  country: TCountryCode;
  setLanguage: (newLang: SupportedLanguage) => void;
  setSourceContext: (newSourceContext: SourceContext | null) => void;
  sourceContext: SourceContext | null;
}>({
  lang: "en",
  country: "NO",
  setLanguage: noop,
  setSourceContext: noop,
  sourceContext: null
});
function LocaleProvider(props: {
  language: SupportedLanguage;
  children: ReactNode;
}) {
  const router = useRouter();
  const pathname = usePathname();
  const country = getCountryFromTimezone();
  const {
    timeZone
  } = Intl.DateTimeFormat().resolvedOptions();
  useEffect(() => {
    new Cookies().set(countryCookieId, country, {
      path: "/"
    });
  }, [country]);
  useEffect(() => {
    new Cookies().set(timezoneCookieId, timeZone, {
      path: "/"
    });
  }, [timeZone]);
  const setLanguage = useCallback((newLang: SupportedLanguage) => {
    // update user preference cookie
    new Cookies().set(languageCookieId, newLang, {
      path: "/"
    });
    // refresh server component renders. This in turns propagates into the provider
    document.documentElement.lang = newLang;
    if (pathname && languages.map(el => el.id).some(el => pathname.startsWith(`/${el}/`))) {
      router.push(pathname.replace(/^\/[a-z]{2}\//, `/${newLang}/`));
    } else {
      router.refresh();
    }
  }, [pathname, router]);
  useEffect(() => {
    i18next.changeLanguage(props.language).catch(console.error);
  }, [props.language]);
  const [sourceContext, setSourceContext] = useSessionStorage<{
    url: string;
    type: "listing" | "company" | "vouch";
    date: Date;
  } | null>("sourceContext", null);
  useEffect(() => {
    if (sourceContext && (!sourceContext.date || dayjs().diff(sourceContext.date, "days") >= 1)) {
      setSourceContext(null);
    }
  }, [sourceContext, setSourceContext]);
  return <LocaleContextProvider value={{
    lang: props.language,
    setLanguage,
    country: country,
    setSourceContext,
    sourceContext
  }} data-sentry-element="LocaleContextProvider" data-sentry-component="LocaleProvider" data-sentry-source-file="LocaleProvider.tsx">
      {props.children}
    </LocaleContextProvider>;
}
export { LocaleProvider, useLocaleProvider, LocaleContextProvider as StorybookBaseLocaleProvider };