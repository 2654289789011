import type { Experience, Industry, RelationToCandidate } from "@prisma/client";
import type { TCountryCode } from "countries-list";
import { Currency, Language } from "@prisma/client";
import { countries } from "countries-list";

// These helpers are in this utility file instead of in the LocaleProvider so they can be imported on both server and client

// export enum SupportedLanguage {
//   Norwegian = "no",
//   English = "en",
// }
export const SUPPORTED_LANGUAGES = ["no", "en", "dk"] as const;
export type SupportedLanguage = (typeof SUPPORTED_LANGUAGES)[number];

export const languages: { id: SupportedLanguage; db: Language; alias: string[] }[] = [
  { id: "en", db: Language.EN, alias: [] },
  { id: "dk", db: Language.DK, alias: [] },
  { id: "no", db: Language.NO, alias: ["nn", "nb"] },
];
export const countryToCurrency = (country: TCountryCode) => {
  switch (country) {
    case "NO":
      return Currency.NOK;
    case "SE":
      return Currency.SEK;
    case "DK":
      return Currency.DKK;
    case "GB":
      return Currency.GBP;
    case "AU":
      return Currency.AUD;
    case "US":
      return Currency.USD;
    case "DE":
      return Currency.EUR;
    default:
      return Currency.EUR;
  }
};

export const getSupportedLanguageFromDB = (db?: Language | null): SupportedLanguage => {
  return languages.find((lang) => lang.db === db)?.id || "en";
};

export interface WithLangParam {
  params: {
    lang: SupportedLanguage;
  };
}

// supports both string and HTML
export type TranslationNode = {
  [key in SupportedLanguage]:
    | string
    | React.ReactNode
    | ((...params: string[]) => string | React.ReactNode);
  // | ((arg?: string) => string | React.ReactNode);
};
// Let's us create our own nested object structure while guaranteeing that all leaves have all supported languages
type Leaves<T> = {
  [n: string]: T | Leaves<T>;
};

// ? Better name?
export type PageContent = Leaves<TranslationNode>;

// If you need some content to be injected with variables, you can just create a structure that does not follow the above.
// I think it won't happen in too many places to be bothersome

// Use in generateStaticParams() in page.tsx files
export const staticLanguageParams = Object.values(SUPPORTED_LANGUAGES).map((lang) => ({
  lang,
}));

export const languageCookieId = "languageCookie";
export const countryCookieId = "countryCookie";
export const timezoneCookieId = "timezoneCookieId";

// In the end not used because it can't be reliably used on both server and client.
// Better to just traverse the object and end with [lang] manually
// function getTranslation(
//   content: TranslationNode,
//   language: SupportedLanguage
// ): string | React.ReactNode {
//   const translation = content[language];
//   return translation;
// }

export const tIndustries: Record<SupportedLanguage, Record<Industry, string>> = {
  en: {
    AKVAKULTUR: "Aquaculture",
    HAVBRUK: "Aquaculture (seawater)",
    FISKERI: "Fishery",
    EMPLOYER_EMPLOYEE_ORGANIZATIONS: "Employer and Employee Organizations",
    ARCHITECTURE_AREA: "Architecture and Area",
    ARCHIVE_LIBRARY: "Archive and Library",
    BANK: "Bank",
    CHILD_SCHOOL_EDUCATION: "Child, School, and Education",
    CAR_VEHICLE_WORKSHOP: "Car, Vehicle, and Workshop",
    CAR_PARTS_MANUFACTURING: "Car and Car Parts Manufacturing",
    STORE_RETAIL: "Store and Retail",
    CONSTRUCTION: "Construction",
    MAINTENANCE_SERVICES: "Maintenance Services",
    ANIMALS_ANIMAL_HEALTH: "Animals and Animal Health",
    REAL_ESTATE: "Real Estate",
    ELECTRONICS: "Electronics",
    EVENTS: "Events and Arrangements",
    PHARMACEUTICAL_MEDICINE: "Pharmaceutical and Medicine",
    FINANCE: "Finance",
    PUBLISHING_PRINTING: "Publishing and Printing",
    INSURANCE: "Insurance",
    RESEARCH_EDUCATION_SCIENCE: "Research, Education, and Science",
    WHOLESALE_IMPORT_EXPORT: "Wholesale Import/Export",
    HR: "Human Resources",
    HEALTHCARE: "Healthcare and Care",
    HOTEL_ACCOMMODATION: "Hotel and Accommodation",
    INFORMATION_TECHNOLOGY: "Information Technology",
    NON_PROFIT_ORGANIZATIONS: "Non-Profit Organizations",
    SPORTS_TRAINING: "Sports and Training",
    INDUSTRY_MANUFACTURING: "Industry and Manufacturing",
    ONLINE_SERVICES: "Online Services",
    LEGAL_SERVICES: "Legal Services",
    CHEMICAL_INDUSTRY: "Chemical Industry",
    CONSULTING_ADVISORY: "Consulting and Advisory",
    POWER_ENERGY: "Power and Energy",
    ART_CULTURE: "Art and Culture",
    AVIATION: "Aviation",
    MARITIME_OFFSHORE: "Maritime and Offshore",
    MARKET_ANALYSIS: "Market Analysis",
    MARKETING_ADVERTISING: "Marketing and Advertising",
    FOOD_PRODUCTION_INDUSTRY: "Food Production and Industry",
    MEDIA_CONTENT_PRODUCTION: "Media and Content Production",
    MEDICAL_EQUIPMENT_SUPPLIES: "Medical Equipment and Supplies",
    ENVIRONMENTAL_SERVICES: "Environmental Services",
    MUSEUMS_GALLERIES_CULTURAL_HERITAGE: "Museums, Galleries, and Cultural Heritage",
    MUSIC: "Music",
    PUBLIC_ADMINISTRATION: "Public Administration",
    OIL_GAS: "Oil and Gas",
    ORGANIZATIONS: "Organizations",
    ORGANIZATION_DEVELOPMENT: "Organization Development",
    PR_INFORMATION_COMMUNICATION: "PR, Information, and Communication",
    POLICE_SECURITY: "Police and Security",
    TRAVEL_TOURISM: "Travel and Tourism",
    RECRUITMENT: "Recruitment",
    WASTE_CLEANING: "Waste and Cleaning",
    RESTAURANT_FOOD_NIGHTLIFE: "Restaurant, Food, and Nightlife",
    SHIPPING: "Shipping",
    TELECOMMUNICATIONS: "Telecommunications",
    TRANSPORT_LOGISTICS: "Transport and Logistics",
    ENTERTAINMENT: "Entertainment",
    ECONOMY_ACCOUNTING: "Economy and Accounting",
    OTHER: "Other",
    PRIVATE_EQUITY: "Private Equity",
    SOFTWARE_DEVELOPMENT: "Software Development",
    VENTURE_CAPITAL: "Venture Capital",
  },
  no: {
    AKVAKULTUR: "Akvakultur",
    HAVBRUK: "Havbruk",
    FISKERI: "Fiskeri",
    EMPLOYER_EMPLOYEE_ORGANIZATIONS: "Arbeidstaker- og arbeidsgiverorganisasjoner",
    ARCHITECTURE_AREA: "Arkitektur og areal",
    ARCHIVE_LIBRARY: "Arkiv og bibliotek",
    BANK: "Bank",
    CHILD_SCHOOL_EDUCATION: "Barn, skole og undervisning",
    CAR_VEHICLE_WORKSHOP: "Bil, kjøretøy og verksted",
    CAR_PARTS_MANUFACTURING: "Bil- og bildelproduksjon",
    STORE_RETAIL: "Butikk og varehandel",
    CONSTRUCTION: "Bygg og anlegg",
    MAINTENANCE_SERVICES: "Drift og vedlikeholdtjenester",
    ANIMALS_ANIMAL_HEALTH: "Dyr og dyrehelse",
    REAL_ESTATE: "Eiendom",
    ELECTRONICS: "Elektronikk",
    EVENTS: "Event og arrangementer",
    PHARMACEUTICAL_MEDICINE: "Farmasi og legemiddel",
    FINANCE: "Finans",
    PUBLISHING_PRINTING: "Forlag og trykkeri",
    INSURANCE: "Forsikring",
    RESEARCH_EDUCATION_SCIENCE: "Forskning, utdanning og vitenskap",
    WHOLESALE_IMPORT_EXPORT: "Grossisthandel import/eksport",
    HR: "HR",
    HEALTHCARE: "Helse og omsorg",
    HOTEL_ACCOMMODATION: "Hotell og overnatting",
    INFORMATION_TECHNOLOGY: "IT",
    NON_PROFIT_ORGANIZATIONS: "Ideelle organisasjoner",
    SPORTS_TRAINING: "Idrett og trening",
    INDUSTRY_MANUFACTURING: "Industri og produksjon",
    ONLINE_SERVICES: "Internettbaserte tjenester",
    LEGAL_SERVICES: "Juridiske tjenester",
    CHEMICAL_INDUSTRY: "Kjemisk industri",
    CONSULTING_ADVISORY: "Konsulent og rådgivning",
    POWER_ENERGY: "Kraft og energi",
    ART_CULTURE: "Kunst og kultur",
    AVIATION: "Luftfart",
    MARITIME_OFFSHORE: "Maritim og offshore",
    MARKET_ANALYSIS: "Markedsanalyse",
    MARKETING_ADVERTISING: "Markedsføring og annonsering",
    FOOD_PRODUCTION_INDUSTRY: "Matvareproduksjon og -industri",
    MEDIA_CONTENT_PRODUCTION: "Medie- og innholdsproduksjon",
    MEDICAL_EQUIPMENT_SUPPLIES: "Medisinsk utstyr og rekvisita",
    ENVIRONMENTAL_SERVICES: "Miljøtjenester",
    MUSEUMS_GALLERIES_CULTURAL_HERITAGE: "Museer, gallerier og kulturminne",
    MUSIC: "Musikk",
    PUBLIC_ADMINISTRATION: "Offentlig administrasjon",
    OIL_GAS: "Olje og gass",
    ORGANIZATIONS: "Organisasjoner",
    ORGANIZATION_DEVELOPMENT: "Organisasjonsutvikling",
    PR_INFORMATION_COMMUNICATION: "PR, informasjon og kommunikasjon",
    POLICE_SECURITY: "Politi og sikkerhet",
    TRAVEL_TOURISM: "Reise og turisme",
    RECRUITMENT: "Rekruttering",
    WASTE_CLEANING: "Renovasjon og renhold",
    RESTAURANT_FOOD_NIGHTLIFE: "Restaurant, mat og uteliv",
    SHIPPING: "Shipping",
    TELECOMMUNICATIONS: "Telekommunikasjon",
    TRANSPORT_LOGISTICS: "Transport og logistikk",
    ENTERTAINMENT: "Underholdning",
    ECONOMY_ACCOUNTING: "Økonomi og regnskap",
    OTHER: "Annet",
    PRIVATE_EQUITY: "Private Equity",
    SOFTWARE_DEVELOPMENT: "Software Development",
    VENTURE_CAPITAL: "Venture Capital",
  },
  dk: {
    AKVAKULTUR: "Akvakultur",
    HAVBRUK: "Havbrug",
    FISKERI: "Fiskeri",
    EMPLOYER_EMPLOYEE_ORGANIZATIONS: "Arbejdsgiver- og arbejdstagerorganisationer",
    ARCHITECTURE_AREA: "Arkitektur og område",
    ARCHIVE_LIBRARY: "Arkiv og bibliotek",
    BANK: "Bank",
    CHILD_SCHOOL_EDUCATION: "Børn, skole og uddannelse",
    CAR_VEHICLE_WORKSHOP: "Bil, køretøj og værksted",
    CAR_PARTS_MANUFACTURING: "Bil- og reservedelsproduktion",
    STORE_RETAIL: "Butik og detailhandel",
    CONSTRUCTION: "Byggeri og anlæg",
    MAINTENANCE_SERVICES: "Vedligeholdelsestjenester",
    ANIMALS_ANIMAL_HEALTH: "Dyr og dyresundhed",
    REAL_ESTATE: "Ejendom",
    ELECTRONICS: "Elektronik",
    EVENTS: "Events og arrangementer",
    PHARMACEUTICAL_MEDICINE: "Farmaceutisk og medicin",
    FINANCE: "Finans",
    PUBLISHING_PRINTING: "Forlag og trykkeri",
    INSURANCE: "Forsikring",
    RESEARCH_EDUCATION_SCIENCE: "Forskning, uddannelse og videnskab",
    WHOLESALE_IMPORT_EXPORT: "Engroshandel import/eksport",
    HR: "HR",
    HEALTHCARE: "Sundhed og omsorg",
    HOTEL_ACCOMMODATION: "Hotel og overnatning",
    INFORMATION_TECHNOLOGY: "IT",
    NON_PROFIT_ORGANIZATIONS: "Ideelle organisationer",
    SPORTS_TRAINING: "Sport og træning",
    INDUSTRY_MANUFACTURING: "Industri og produktion",
    ONLINE_SERVICES: "Online tjenester",
    LEGAL_SERVICES: "Juridiske tjenester",
    CHEMICAL_INDUSTRY: "Kemisk industri",
    CONSULTING_ADVISORY: "Konsulent og rådgivning",
    POWER_ENERGY: "Energi og kraft",
    ART_CULTURE: "Kunst og kultur",
    AVIATION: "Luftfart",
    MARITIME_OFFSHORE: "Maritimt og offshore",
    MARKET_ANALYSIS: "Markedsanalyse",
    MARKETING_ADVERTISING: "Markedsføring og reklame",
    FOOD_PRODUCTION_INDUSTRY: "Fødevareproduktion og industri",
    MEDIA_CONTENT_PRODUCTION: "Medie- og indholdsproduktion",
    MEDICAL_EQUIPMENT_SUPPLIES: "Medicinsk udstyr og forsyninger",
    ENVIRONMENTAL_SERVICES: "Miljøtjenester",
    MUSEUMS_GALLERIES_CULTURAL_HERITAGE: "Museer, gallerier og kulturarv",
    MUSIC: "Musik",
    PUBLIC_ADMINISTRATION: "Offentlig administration",
    OIL_GAS: "Olie og gas",
    ORGANIZATIONS: "Organisationer",
    ORGANIZATION_DEVELOPMENT: "Organisationsudvikling",
    PR_INFORMATION_COMMUNICATION: "PR, information og kommunikation",
    POLICE_SECURITY: "Politi og sikkerhed",
    TRAVEL_TOURISM: "Rejse og turisme",
    RECRUITMENT: "Rekruttering",
    WASTE_CLEANING: "Affald og rengøring",
    RESTAURANT_FOOD_NIGHTLIFE: "Restaurant, mad og natteliv",
    SHIPPING: "Søfart",
    TELECOMMUNICATIONS: "Telekommunikation",
    TRANSPORT_LOGISTICS: "Transport og logistik",
    ENTERTAINMENT: "Underholdning",
    ECONOMY_ACCOUNTING: "Økonomi og regnskab",
    OTHER: "Andet",
    PRIVATE_EQUITY: "Private Equity",
    SOFTWARE_DEVELOPMENT: "Softwareudvikling",
    VENTURE_CAPITAL: "Venturekapital",
  },
};

export const tExperience = {
  en: {
    ENTRY: "Entry",
    EXECUTIVE: "Executive",
    JUNIOR: "Junior",
    SENIOR: "Senior",
    INTERN: "Intern",
    MANAGER: "Manager",
  },
  no: {
    ENTRY: "Nyutdannet",
    JUNIOR: "Junior",
    SENIOR: "Senior",
    INTERN: "Intern",
    MANAGER: "Leder",
    EXECUTIVE: "Executive",
  },
  dk: {
    ENTRY: "Nyuddannet",
    JUNIOR: "Junior",
    SENIOR: "Senior",
    INTERN: "Intern",
    MANAGER: "Leder",
    EXECUTIVE: "Executive",
  },
} as { [key in SupportedLanguage]: { [key in Experience]: string } };

export const tRelationToCandidate: Record<
  RelationToCandidate,
  Record<SupportedLanguage, string>
> = {
  COLLEGUE: {
    no: "Kollega",
    en: "Colleague",
    dk: "Kollega",
  },
  FRIEND: {
    no: "Venn",
    en: "Friend",
    dk: "Ven",
  },
  RUMOR: {
    no: "Rykte",
    en: "Rumor",
    dk: "Rygte",
  },
  NO_RELATION: {
    no: "Ingen relasjon",
    en: "No relation",
    dk: "Ingen relation",
  },
  OTHER: {
    no: "Annet",
    en: "Other",
    dk: "Andet",
  },
};

export const tCountry = Object.entries(countries).reduce(
  (acc, [key, value]) => ({
    ...acc,
    [key]: `${value.name} (${value.native})`,
  }),
  {} as Record<TCountryCode, string>,
);

export const CountrySelectOptions = Object.entries(tCountry).map(([key, el]) => ({
  label: el,
  value: key as TCountryCode,
}));

export const tLanguage = {
  no: {
    no: "Norsk",
    en: "Norwegian",
    dk: "Norsk",
  },
  en: {
    no: "Engelsk",
    en: "English",
    dk: "Engelsk",
  },
  dk: {
    no: "Dansk",
    en: "Danish",
    dk: "Dansk",
  },
} satisfies { [key in SupportedLanguage]: { [key in SupportedLanguage]: string } };

export const geti18nOfItems = <T extends { i18n?: unknown; name: string }>(
  items: T[],
  lang: SupportedLanguage,
) => {
  return items.map((it) => ({
    ...it,
    name:
      (it.i18n as Record<Language, string>)[languages.find((l) => l.id === lang)?.db || "EN"] ||
      it.name,
  }));
};
